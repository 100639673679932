$(document).ready(function() {
    $(document).on('click', '#get-offers-btn', function (e) {
        e.preventDefault();
        let url = $(this).data('link');
        // let url = cf.opt.catalogUrl + 'gauti-pasiulyma/';
        let params = $(this).data('params');
        params.product_item_id = $(this).data('id');
        params.state = 'offer-search';
        $.redirect(url, params, "POST");
    });
});