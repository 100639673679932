window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);

window.get_vw = function() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

window.get_vh = function() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.search_air = {
    display_input_init: "#air_date_from",
    opt : {
        first_lock : false,

        currPage: 0, //month
        base_div: '.datepicker_air_div ',

        todays_date : false,
        active_date : false,

        active_date_range_minus : '01/01/2022',
        active_date_range_plus  : '01/01/2022',

        default_day_range : 0,

        check_month : false,
        check_year : false,
        check_day : false,

        active_months : false,

        begin_date: null,
        end_date: null,
        // nusako kurią datą reikia žymėti pradžios ar pabaigos
        selected_date_input: 'earliest',
    },

    first_month : 'Sausis',
    last_month : 'Gruodis',

    mos : [
        'Sausis','Vasaris','Kovas',
        'Balandis','Gegužė', 'Birželis',
        'Liepa','Rugpjūtis','Rugsėjis',
        'Spalis','Lapkritis','Gruodis'
    ],
    days : ['P', 'A', 'T' , 'K', 'Pn', 'Š', 'S'],
    date_format_type: 1,

    redraw: function() {
        this._getDays(this.opt.currPage);
    },

    init: function (options) {
        let self = this;
        let display_input = $(self.display_input_init);

        self.opt.todays_date = new Date(self.format_date());

        if (window.location.hostname.indexOf('airguru.ee') != -1) {
            self.mos = [ "Jaanuar", "Veebruar", "Märts", "Aprill", "Mai", "Juuni",
                "Juuli", "August", "September", "Oktoober", "November", "Detsember" ];
            self.days = [ "E", "T", "K", "N", "R", "L", "P" ];
            self.first_month = "Jaanuar";
            self.last_month = "Detsember";
            self.date_format_type = 2;
        } else if (window.location.hostname.indexOf('airguru.bg') != -1) {
            self.mos = [ "Януари", "Февруари", "Март", "Април", "Май", "Юни",
                "Юли", "Август", "Септември", "Октомври", "Ноември", "Декември" ];
            self.days = [  "Не", "По", "Вт", "Ср", "Че", "Пе", "Съ" ];
            self.first_month = "Януари";
            self.last_month = "Декември";
            self.date_format_type = 2;
        } else if (window.location.hostname.indexOf('airguru.hu') != -1) {
            self.mos =  [ "Január", "Február", "Március", "Április", "Május", "Június",
                "Július", "Augusztus", "Szeptember", "Október", "November", "December" ];
            self.days =  [ "V", "H", "K", "Sze", "Cs", "P", "Szo" ];
            self.first_month = "Január";
            self.last_month = "December";
            self.date_format_type = 2;
        } else if (window.location.hostname.indexOf('airguru.lv') != -1) {
            self.mos =  [ "Janvāris", "Februāris", "Marts", "Aprīlis", "Maijs", "Jūnijs",
                "Jūlijs", "Augusts", "Septembris", "Oktobris", "Novembris", "Decembris" ];
            self.days =  ["Pr", "Ot", "Tr", "Ct", "Pk", "Ss", "Sv" ];
            self.first_month = "Janvāris";
            self.last_month = "Decembris";
            self.date_format_type = 2;
        }

        //base values
        self.selected_date_input = 'earliest';

        if (display_input.attr('data-dd')) {
            self.opt.check_day = display_input.attr('data-dd');
            self.opt.check_month =  display_input.attr('data-mm');
            self.opt.check_year =  display_input.attr('data-yy');
        }

        if ($(self.opt.base_div + " #form_months").attr('value')) {
            self.opt.active_months = $(self.opt.base_div + " #form_months").data('mm') + '/' + $(self.opt.base_div + " #form_months").data('yy');
        }

        if (self.opt.check_year) {
            self.active_day(self.opt.check_month + '/' + self.opt.check_day + '/' + self.opt.check_year);
        }

        if (self.opt.active_months) {
            self.opt.active_date = false;
        }

        self.create_dates_range();
        self._date_input_format();

        $(document).on('click', "#earliest_departure_date_input", function() {
            self.selected_date_input = 'earliest';
            $("#latest_departure_date_input").css({"border": "1px solid #e9ecef"});
            $(this).css({"border": "1px solid #007bff"});
        });

        $(document).on('click', "#latest_departure_date_input", function() {
            self.selected_date_input = 'latest';
            $("#earliest_departure_date_input").css({"border": "1px solid #e9ecef"});
            $(this).css({"border": "1px solid #007bff"});
        });

        $(document).on('click touch', ".change_btn", function() {
            self._showAirDatepicker();
        })

        $(document).on('click touch', self.display_input_init, function(e) {
            self._showAirDatepicker();
        });

        // hide out click;
        $(document).on('click touch', function(e) {
            let container = $('#datepicker_air');
            let destination_btn = $(self.display_input_init);
            let target = e.target;

            if (target && container) {
                if (!container.is(target) && container.has(target).length === 0 &&
                    !destination_btn.is(target) && (document.getElementById('datepicker_air') && !document.getElementById('datepicker_air').contains(target))
                ) {
                    //appended issue
                    if (get_vw() >= screen_md_min || $('body#type_index').length > 0) {
                        if (!$(target).hasClass('destination_continue') && !$(target).hasClass('change_btn') && !$(target).hasClass('cell_input') && !$(target).hasClass('month_btn')) {
                            self._hideAirDatepicker();
                        }
                    }
                }
            }
        });

        $(document).on('click touch', '.air_close, .datepicker_air > .top-wrp > .dropdown-header .dropdown-close', function() {
            self._hideAirDatepicker();
            if (get_vw() < screen_md_min && $('body#type_index').length <= 0) {
                $('#collections_listing_search .search-form').removeClass("open");
            }
        });

        $(document).on('click touch', self.opt.base_div + " .day_select_btn", function(e) {
            e.preventDefault();
            self._hideAirDatepicker();

            // if date selected, submit search form
            if (self.opt.active_date) {
                if (!$('body#type_index').length) {
                    window.customFilters.filtersSubmitted();
                }
            }
        });

        $(document).on('click touch', self.opt.base_div + " .cell input", function(e) {
            if (!$(this).hasClass('disabled')) {
                self.opt.check_year = $(this).data('yy');
                self.opt.check_month = $(this).data('mm');
                self.opt.check_day = $(this).data('dd');

                self.opt.active_months = false;

                if (self.opt.check_year) {
                    self.active_day(self.opt.check_month + '/' + self.opt.check_day + '/' + self.opt.check_year);
                    self.curr_range(self.opt.check_month + '/' + self.opt.check_day + '/' + self.opt.check_year);
                }

                let year =  self.opt.check_year;
                if (self.opt.currPage === 0 && self.opt.check_month == 12) {
                    self.opt.currPage = 11;
                }

                if (self.opt.active_date.getMonth() === 0 && self.opt.currPage === 11) {
                    self.opt.check_month = 11;
                    self.opt.check_year = self.opt.check_year-1;
                }

                self.create_dates_range();

                self._date_input_format();

                self._getDays(self.opt.currPage);

                self.filterDateAndRange();
            }
        });

        $(document).on('click touch', self.opt.base_div + " #next_month", function() {
            self.opt.check_year = parseInt($(self.opt.base_div + " #before_year").text());
            if (self.opt.currPage < 12) {
                self.opt.currPage = self.opt.currPage + 1;
                if (self.opt.currPage === 12) {
                    self.opt.currPage = 0;
                    $(self.opt.base_div + " #before_year").text(parseInt($(self.opt.base_div + " #before_year").text())  + 1)  ;
                    self.opt.check_year = parseInt($(self.opt.base_div + " #before_year").text());
                }

                self._getDays(self.opt.currPage);
            }
        });

        $(document).on('click touch', self.opt.base_div + " #prev_month", function() {
            if (self.opt.currPage > 0) {
                self.opt.currPage =  self.opt.currPage-1;
                self._getDays(self.opt.currPage);
            } else {
                self.opt.currPage = 11;
                $(self.opt.base_div + " #before_year").text(parseInt($(self.opt.base_div + " #before_year").text())  - 1)  ;
                self.opt.check_year = parseInt($(self.opt.base_div + " #before_year").text());

                self._getDays(self.opt.currPage);
            }
        });
        var previousDays = false;

        $(document).on('click touch', self.opt.base_div + " .days-select-wrp input[name='ftype_days']", function(e) {

            e.stopImmediatePropagation();
            // e.preventDefault();
            e.stopPropagation();

            console.log('previousDays:' + previousDays)
            console.log('$(this).val():' + $(this).val())

            if (previousDays === $(this).val()) {
                $(this).prop('checked', false);
                previousDays = false;
            } else {
                $(this).prop('checked', true);
                previousDays = $(this).val();
            }
            self.opt.default_day_range = $($(self.opt.base_div + " .days-select-wrp input[name='ftype_days']:checked")[0]).val();

            self.filterDateAndRange();

            $(document).find( self.opt.base_div + " .cell input.active").click();
        });

        $(document).on('click', self.opt.base_div + " #prev_year", function() {
            $(self.opt.base_div + " .year_div").text(  parseInt($(self.opt.base_div + " .year_div").text())  - 1   )  ;
            self.opt.check_year = $(self.opt.base_div + " .year_div").text();
            self.doMonths(self.opt.currPage);
        });

        $(document).on('click', self.opt.base_div + " #next_year", function() {
            $(self.opt.base_div + " .year_div").text(  parseInt($(self.opt.base_div + " .year_div").text())  + 1   )  ;
            self.opt.check_year = $(self.opt.base_div + " .year_div").text();
            self.doMonths(self.opt.currPage);
        });

        $(document).on('click touch', self.opt.base_div + " #tab_month .month_select .month_btn", function(e) {
            if (!$(this).hasClass('disabled')) {
                $(self.opt.base_div + " #tab_month .month_select .month_btn").removeClass('active');
                $(this).addClass('active');

                self.opt.check_year = $(self.opt.base_div + " .year_div").text();

                self.opt.active_months = ($(this).data('mm') + 1) + '/' + $(this).data('yy');
                self.opt.active_date = false;

                self._date_input_format();

                self.opt.currPage = $(this).data('mm');
                self._getDays(($(this).data('mm')), $(this).data('yy'));

                self.filterMonth();
            }
        });


        $('.calendar_filter_submit').on('click touch', function() {
            window.customFilters.filtersSubmitted();
        });

        $('#all_dates, #reset_dates').on('click touch', function() {
            self.opt.active_months = false;
            self.opt.active_date = false;
            self.filterAll();
            self._date_input_format();
            self._getDays(null,null);

            if (!mobileCheck()) {
                self._hideAirDatepicker();
            }
        })

    },

    _showAirDatepicker() {
        let self = this;

        if ($(self.display_input_init).attr('data-dd')) {
            self.opt.check_month = $(self.display_input_init).attr('data-mm');
            self.opt.currPage = $(self.display_input_init).attr('data-mm') - 1;

            self.opt.check_day = $(self.display_input_init).attr('data-dd');
            self.opt.check_year = $(self.display_input_init).attr('data-yy');
        } else {
            let date = self.todays_day_return();
            self.opt.check_month = date.mm;
            self.opt.currPage = date.mm - 1;

            self.opt.check_day = date.dd;
            self.opt.check_year = date.yyyy;
        }


        if (!self.opt.active_months || !$(self.display_input_init).attr('data-dd')) {
            self.active_day( $(self.display_input_init).attr('data-mm') + '/' + $(self.display_input_init).attr('data-dd')  + '/' + $(self.display_input_init).attr('data-yy'));
        }
        $('#collections_icons_index_listing').addClass('extra_z_index2');
        $('#collections_listing_search').addClass('extra_z_index2');
        $('#filter-btn-holder').addClass('no_z_index2');

        self._getDays(self.opt.currPage,self.opt.check_year);

        $(self.opt.base_div).find('.datepicker_air').addClass('show');
        $('body').addClass('scroll-lock-mob-datepicker');
        // if (get_vw() >= screen_md_min || $('body#type_index').length > 0) {
        //     $(self.opt.base_div + " .datepicker_air").css("display"," block");
        // } else {
        //     $('#collections_listing_search .search-form').addClass("open");
        // }

    },
    _hideAirDatepicker() {
        $('.datepicker_air').removeClass('show');
        // if (get_vw() >= screen_md_min || $('body#type_index').length > 0) {
        //     $('#datepicker_air').hide();
        // }
        $('#collections_icons_index_listing').removeClass('extra_z_index2');
        $('#collections_listing_search').removeClass('extra_z_index2');
        $('#filter-btn-holder').removeClass('no_z_index2');
        $('body').removeClass('scroll-lock-mob-datepicker');
    },

    _getDays(month,year = null) {
        let self = this;

        self.opt.first_lock = true;

        self.active_day_range();

        $(self.opt.base_div + " .dt_able").empty();
        $(self.opt.base_div + " .dt_able_last").empty();

        if (year === null || year === '') {
            if (self.opt.check_year === null || self.opt.check_year === '' ) {
                date = self.todays_day_return();
                self.opt.check_year = date.yyyy
            }
            year = self.opt.check_year ;
        }

        if (month === null || month === '' || month  < 0) {
            if (self.opt.check_month === null || self.opt.check_month === '' ) {
                date = self.todays_day_return();
                self.opt.check_month = date.mm
            }
            month = self.opt.check_month  ;
        }


        self._datepicker_table_header_create(month);

        let currentMonth = month;
        let currentYear = self.opt.check_year;

        let nextMonth = month+1;
        let nextYear = self.opt.check_year;

        if(nextMonth === 12) {
            nextMonth = 0;
            nextYear = nextYear + 1;
        }

        //NEXT MONTH
        self.opt.check_month = nextMonth+1;
        self.opt.check_year = nextYear;
        let firstDay = new Date(nextYear, nextMonth, 0);
        let lastDay = new Date(nextMonth+1 === 12?nextYear+1:nextYear, nextMonth+1 === 12?0:nextMonth+1, 0);
        self._datepicker_table_create(firstDay, lastDay, firstDay.getDay(), 1,  '');


        // this month
        self.opt.check_month = currentMonth+1;
        self.opt.check_year = currentYear;

        firstDay = new Date(currentYear, currentMonth, 0);
        lastDay = new Date(nextYear, nextMonth, 0);
        self._datepicker_table_create(firstDay, lastDay, firstDay.getDay(), 1,  '_last');
        self.doMonths(currentMonth);


    },

    _datepicker_table_header_create(month) {
        let self = this;

        let less_month = month;
        let more_month = month+1;

        if (less_month === -1) {
            less_month = 11;
        }

        if (more_month === 12) {
            more_month = 0;
            $(self.opt.base_div + " #current_year").html(parseInt(self.opt.check_year)+1);
        } else {
            $(self.opt.base_div + " #current_year").html(self.opt.check_year);
        }

        $(self.opt.base_div + " .month_title").html(self.mos[more_month]);
        $(self.opt.base_div + " .before_month_title").html(self.mos[less_month]);
        $(self.opt.base_div + " #before_year").html(self.opt.check_year);
    },

    _datepicker_table_create(firstDay, lastDay, offset, dayCount, last = '') {
        let self = this;

        let head_line = "<thead><tr>";
        for (i = 0; i < 7; i++) {
            head_line += "<td class='dt_head'>"  + self.days[i] + "</td>";
        }
        head_line += "</tr></thead>";
        $(self.opt.base_div + " .dt_able" + last).append(head_line);


        let clasess = '';
        let lastDayPrinted = false;

        for (i = 0; i < 7; i++) {
            if (lastDayPrinted) {
                break;
            }
            let row_name = "row" + last + "-" + i;
            let row_selector = "." + row_name;

            $(self.opt.base_div + ' .dt_able'+ last).append("<tr class='" + row_name + "'>");

            for (rw = 0; rw < 7; rw++) {
                if (offset === 0) {
                    clasess = self.day_classes(dayCount);
                    let availClass='';
                    if(window.availableDates && window.availableDates.length > 0 && !window.availableDates.includes(self.opt.check_year+'-'+(self.opt.check_month<10?'0'+self.opt.check_month:self.opt.check_month)+'-'+(dayCount<10?'0'+dayCount:dayCount))) {
                        availClass='date-unavailable';
                    }
                    $(self.opt.base_div + row_selector).append("<td  class='cell " + "cell"+ last + "_" + dayCount + "'>"
                        + "<input type='button' class='"+clasess+" "+availClass+" cell_input day_val"+ last + dayCount + "'" + " value= '" + dayCount + "' data-dd='"+dayCount+"' data-mm='"+self.opt.check_month+"' data-yy='"+self.opt.check_year+"'>" +
                        '</td>');
                    if (dayCount === lastDay.getDate()) {
                        lastDayPrinted = true;
                    }
                    if (dayCount >= lastDay.getDate()) {
                        break;
                    }

                    dayCount++;
                } else {
                    $(self.opt.base_div + row_selector).append('<td>' + '</td>');
                    offset--;
                }
            }

            $(self.opt.base_div + ' .dt_able' + last).append('</tr>');
        }
    },
    day_classes(dayCount) {
        let self = this;

        let active_range = false;
        let active = false;
        let disable = self.check_day_disable(dayCount);

        if (!self.opt.active_months) {
            active_range = self.check_day_active_range(dayCount);
            active = self.check_day_active(dayCount);
        }

        let classes = '';

        if (active) {
            classes += 'active ';
        } else {
            if (disable) {
                classes += 'disabled ';
            }
            if (active_range) {
                classes += 'active_range ';
            }
        }


        let first_date = self.opt.active_date_range_minus.getTime();
        let last_date = self.opt.active_date_range_plus.getTime();
        let checked_date = self.opt.check_month+"/"+dayCount+"/"+self.opt.check_year;
        let d2 = new Date(checked_date);

        if (first_date == d2.getTime() && last_date > first_date) {
            classes += 'range_start ';
        }
        if (last_date == d2.getTime() && first_date < last_date) {
            classes += 'range_end ';
        }

        return  classes;
    },

    check_day_active(dayCount) { /** check if day is active*/
        let self = this;

        let d2 = new Date( self.opt.check_month + "/" + dayCount + "/" + self.opt.check_year );

        if (self.opt.active_date) {
            return d2.getTime() === self.opt.active_date.getTime();
        }
        return false;
    },
    check_day_disable(dayCount) {    /** check if day is disabled*/
        let self = this;

        let checked_date = self.format_date( self.opt.check_month + "/" + dayCount + "/" + self.opt.check_year );
        let d2 = new Date(checked_date);

        return d2.getTime() < self.opt.todays_date.getTime();

    },
    check_day_active_range(dayCount) {  /** Check if day is in range*/
        let self = this;
        let checked_date = self.opt.check_month+"/"+dayCount+"/"+self.opt.check_year;
        let d2 = new Date(checked_date);

        if (d2.getTime() >= self.opt.todays_date.getTime()) { //only from today can be range
            if (self.opt.active_date_range_minus.getTime() <= d2.getTime() && self.opt.active_date_range_plus.getTime() >= d2.getTime()) { // if in range
                return true;
            }
        }

        return false;
    },

    active_day_range() {
        let self = this;

        let d2 = new Date(self.opt.begin_date);
        self.opt.active_date_range_minus = d2;

        let end_date = new Date(self.opt.end_date);
        self.opt.active_date_range_plus = end_date;
    },

    active_day(date = false) {
        let self = this;
        self.opt.todays_date = new Date();
        // self.opt.active_date = self.opt.todays_date ;

        if (date && date !== '//') {
            self.opt.active_date = new Date(date);
        }
    },
    curr_range(date = false) {
        let self = this;
        if (date && date !== '//') {
            let selected_date = new Date(date);

            if (self.opt.begin_date == null && self.opt.end_date == null) {
                self.opt.begin_date = selected_date;
                self.opt.end_date = null;
            } else if (self.opt.begin_date != null && self.opt.end_date == null && (self.opt.begin_date.getTime() < selected_date.getTime())) {
                self.opt.end_date = selected_date;
            } else {
                self.opt.begin_date = selected_date;
                self.opt.end_date = null;
            }
        }
    },

    create_dates_range() {
        let self = this;

        // if (self.selected_date_input == 'earliest') {
        //     if (self.opt.begin_date != null && self.opt.end_date == null) {
        //         if (self.opt.begin_date.getTime() < self.opt.active_date.getTime()) {
        //             self.opt.end_date = self.opt.active_date;
        //         }
        //     } else if (self.opt.active_date != false) {
        //         self.opt.begin_date = self.opt.active_date;
        //     }
        // } else if (self.selected_date_input == 'latest') {
        //     self.opt.end_date = self.opt.active_date;
        // }

        if (self.opt.begin_date != null && self.opt.end_date != null) {
            let one_day = 24 * 60 * 60 * 1000;
            self.opt.default_day_range = Math.round(Math.abs((self.opt.end_date - self.opt.begin_date) / one_day));
        } else {
            self.opt.default_day_range = 0;
        }
    },

    format_date(date = null) {
        let date_normal = new Date();
        if (date && date !== '//') {
            date_normal = date;
        }

        let d = new Date(date_normal);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },
    active_day_return() {
        if (this.opt.active_date) {
            let dd = this.opt.active_date.getDate();
            let mm = this.opt.active_date.getMonth() + 1;
            let yyyy = this.opt.active_date.getFullYear();

            return {yyyy, mm, dd};
        }
        return false;
    },
    todays_day_return() {
        if (this.opt.todays_date) {
            let dd = this.opt.todays_date.getDate();
            let mm = this.opt.todays_date.getMonth() + 1;
            let yyyy = this.opt.todays_date.getFullYear();

            return {yyyy, mm, dd};
        }
        return false;
    },

    format_begin_date() {
        if (this.opt.begin_date) {
            let d = this.opt.begin_date;
            let dd = d.getDate();
            let mm = d.getMonth() + 1;
            let yyyy = d.getFullYear();

            return {yyyy, mm, dd};
        }
        return false;
    },

    format_end_date() {
        if (this.opt.end_date) {
            let d = this.opt.end_date;
            let dd = d.getDate();
            let mm = d.getMonth() + 1;
            let yyyy = d.getFullYear();

            return {yyyy, mm, dd};
        }
        return false;
    },

    format_dates_input_text() {
        let self = this;

        let begin_dt = self.format_begin_date();
        let end_dt = self.format_end_date();

        let text = '';
        if (begin_dt.mm != end_dt.mm) {
            text = self.mos[begin_dt.mm - 1].slice(0, 3);
        } else {
            // rodomas pilnas mėn. pavad.
            text = self.mos[begin_dt.mm - 1];
        }
        text = text + ' ';
        if (begin_dt.mm < 10 && begin_dt.mm.length == 1)
            begin_dt.mm = '0' + begin_dt.mm;
        if (begin_dt.dd < 10 && begin_dt.dd.length == 1)
            begin_dt.dd = '0' + begin_dt.dd;

        text = text + begin_dt.dd;

        if (end_dt) {
            if (begin_dt.mm != end_dt.mm) {
                text = text + 'd. - ';
                text = text + self.mos[end_dt.mm - 1].slice(0, 3);
                text = text + ' ';
            } else {
                text = text + ' - ';
            }
            if (end_dt.mm < 10 && end_dt.mm.length == 1)
                end_dt.mm = '0' + end_dt.mm;
            if (end_dt.dd < 10 && end_dt.dd.length == 1)
                end_dt.dd = '0' + end_dt.dd;
            text = text + end_dt.dd;
        }

        text = text + 'd.';

        return text;
    },
    set_departure_dates()
    {
        let self = this;

        let begin_dt = self.format_begin_date();
        let end_dt = self.format_end_date();

        if (true) {
            if (self.date_format_type == 2) {
                if (begin_dt) {
                    let d = new Date(begin_dt.yyyy, begin_dt.mm - 1, begin_dt.dd);
                    $('#earliest_departure_date_input').val(d.toLocaleDateString("en-GB", {year: "numeric", month: "2-digit", day: "2-digit"}));
                }
                if (end_dt) {
                    let d = new Date(end_dt.yyyy, end_dt.mm - 1, end_dt.dd)
                    $('#latest_departure_date_input').val(d.toLocaleDateString("en-GB", {year: "numeric", month: "2-digit", day: "2-digit"}));
                } else {
                    $('#latest_departure_date_input').val('');
                }
            } else {
                if (begin_dt) {
                    let d = new Date(begin_dt.yyyy, begin_dt.mm - 1, begin_dt.dd);
                    $('#earliest_departure_date_input').val(d.toLocaleDateString("lt-LT", {year: "numeric", month: "2-digit", day: "2-digit"}));
                }
                if (end_dt) {
                    let d = new Date(end_dt.yyyy, end_dt.mm - 1, end_dt.dd);
                    $('#latest_departure_date_input').val(d.toLocaleDateString("lt-LT", {year: "numeric", month: "2-digit", day: "2-digit"}));
                } else {
                    $('#latest_departure_date_input').val('');
                }
            }
        }
    },


    // MONTHS
    doMonths(curMonth) {
        let self = this;
        $(self.opt.base_div + " #tab_month .month_select").html('');

        $(self.opt.base_div + " .year_div").text(self.opt.check_year);

        for (rw = 0; rw < 12; rw++) {
            $(self.opt.base_div + " #tab_month .month_select").append(
                "<button class='month_btn' data-mm='" + rw + "' data-yy='" + self.opt.check_year + "'  >" + self.mos[rw] + "</button>"
            );
        }
        self.month_checker();
    },
    month_checker() {
        let self = this;
        // let date = self.active_day_return();
        let date2 = self.todays_day_return();
        let value = self.opt.active_months;
        let yyyy = null;
        let mmmm = null;
        let item = null;

        $(self.opt.base_div + " #tab_month .month_select .month_btn").removeClass('active').removeClass('disabled').each(function() {
            yyyy = $(this).data('yy');
            mmmm = $(this).data('mm');
            item = $(this);

            if (value === (mmmm + 1) + "/" + yyyy || value  === "0" + (mmmm + 1) + "/" + yyyy) {
                item.addClass('active');
            }

            if ($(this).data('yy') < date2.yyyy || ( $(this).data('yy') === date2.yyyy  && $(this).data('mm') + 1 < date2.mm)) {
                $(this).addClass('disabled');
            }
        })
    },

    // visible input show
    _date_input_format() {
        let self = this;

        if (self.opt.active_date === false && self.opt.active_months === false) {
            date = self.todays_day_return();

            $(self.display_input_init).find('.desktop-txt').html($(self.display_input_init).data('all'));
            $(self.display_input_init).find('.mobile-txt').html($(self.display_input_init).data('all-mob'));
            $(self.display_input_init).attr('data-dd', '');
            $(self.display_input_init).attr('data-mm', '' );
            $(self.display_input_init).attr('data-yy', '');
            self.filterAll();
        } else if (self.opt.active_date !== false) {

            let text = self.format_dates_input_text();
            if (text) {
                $(self.display_input_init).html(text);

                let begin_date = self.format_begin_date();
                $(self.display_input_init).attr('data-dd', begin_date.dd);
                $(self.display_input_init).attr('data-mm', begin_date.mm);
                $(self.display_input_init).attr('data-yy', begin_date.yyyy);

                $('#form_year').val(begin_date.yyyy);
                $('#form_month').val(begin_date.mm);
                $('#form_day').val(begin_date.dd);
            }
            self.filterDateAndRange();
        } else {
            let data = self.opt.active_months.split('/');
            let text = data[1] + " " + self.mos[data[0]-1].slice(0, 4) + ".";
            $(self.display_input_init).html(text);

            if (data[0] < 10 && data[0].length == 1) {
                data[0] = '0' + data[0];
            }

            $(self.display_input_init).attr('data-dd','');
            $(self.display_input_init).attr('data-mm',data[0]);
            $(self.display_input_init).attr('data-yy',data[1]);

            self.filterMonth();
        }

        self.set_departure_dates();
    },

    // Filter
    filterAll() {
        let self = this;
        self.changeFilter('range',false);
        self.changeFilter('date',false);
        self.changeFilter('months',false);
    },
    filterDateAndRange() {
        let self = this;
        let data = self.format_begin_date();
        if (data) {
            let text = self.mos[data.mm - 1].slice(0, 4);
            text = text + ' ';

            if (data.mm < 10 && data.mm.length == 1)
                data.mm = '0' + data.mm;
            if (data.dd < 10 && data.dd.length == 1)
                data.dd = '0' + data.dd;

            text = text + data.dd;

            self.changeFilter('range', self.opt.default_day_range, '±' + self.opt.default_day_range);
            self.changeFilter('date', data.yyyy + "-" + data.mm + "-" + data.dd, text);
            self.changeFilter('months', false);
        }
    },
    filterMonth() {
        let self = this;
        self.changeFilter('range',false);
        self.changeFilter('date',false);

        let data = self.opt.active_months.split('/');
        if (data[0] < 10 && data[0].length == 1)
            data[0] = '0' + data[0];

        self.changeFilter('months', data[1] + '-' + data[0], data[1] + '-' + data[0]);
    },
    changeFilter(type, value, name) {
        let cf = window.customFilters;

        if (typeof cf != 'undefined') {
            cf.removeValues(type);
            cf.removeNames(type);

            if (value) {
                cf.addValue(type, value);
                cf.addName(type, name);
            }
        }
    },
    set_begin_date(y, m, d) {
        if (!isNaN(y) && !isNaN(m)) {
            this.opt.begin_date = new Date(y, m - 1, d);
        }
    },
    set_end_date(y, m, d) {
        if (!isNaN(y) && !isNaN(m)) {
            this.opt.end_date = new Date(y, m - 1, d);
        }
    }
}


window.search_duration_air = {
    display_input: "#air_duration",
    container_div: '#duration_air_div',
    container_id: 'duration_air',
    container: '#duration_air',
    opt: {
        /*
        days_from: 7,
        days_to: 10,
         */
    },
    init() {
        let self = this;

        $(document).ready(function() {
            if ($('#datepicker_air_div').length > 0) {
                var duration = $('#datepicker_air_div input[name="duration"]').val().split('-');
                if (duration.length > 0 && Number(duration[0]) > 0) {
                    self.opt.days_from = Number(duration[0]);
                    self.opt.days_to = Number(duration[1]);
                }
            }

            self._check_days();
            self._input_format();
            self._check_increments();
        })

        $(document).on('click touch', self.display_input + ', ' + self.display_input + ' > span'  , function(e) {
            self._show();
        });

        $(document).on('click touch', '.btn-increment' , function(e) {
            self._click_increment(this);
        });

        $(document).on('click touch', '.nights-btn' , function(e) {
            $(document).find("#duration_air > .alert.alert-danger").remove();
            $(document).find(".nights-btn").removeClass('error');
            self._click_range(this);
        });

        // prevent from closing dropdown, becouse of multiselect
        $('#manual-duration-wrp .dropdown-menu').on('click', function(e){
            if (!$(e.target).hasClass('dropdown-close') && !$(e.target).hasClass('act-close-manual-duration')) {
                e.stopPropagation();
            }
        });
        $(document).on('change', 'input[type="checkbox"][name="manual_duration[]"]', function(e) {
            e.preventDefault();

            let selected = [];
            $(this).parents('.manual-duration-selectors').find('input[type="checkbox"][name="manual_duration[]"]:checked').each(function() {
                let curr = {
                    days: $(this).val(),
                    text: $(this).parent().find('.check-label').text()
                };
                selected.push(curr);
            });

            let days_text = $(document).find('#manual-duration-btn .manual-duration-field').data('default-text');
            if (selected.length == 1) {
                days_text = selected[0].text;
            }
            if (selected.length) {
                $(document).find('#manual-duration-btn').addClass('active');
            } else {
                $(document).find('#manual-duration-btn').removeClass('active');
            }

            $(document).find('#manual-duration-btn .manual-duration-field').text(days_text);

            selected.sort(function(a,b)
            {
                return a.days - b.days;
            });
            if (selected.length > 0) {
                if(typeof(selected[0]) != 'undefined') {
                    self.opt.days_from = parseInt(selected[0].days);
                }
                let l = selected.length - 1;
                if (typeof(selected[l]) != 'undefined') {
                    self.opt.days_to = parseInt(selected[l].days);
                }
            } else {
                self.opt.days_from = undefined;
                self.opt.days_to = undefined;
            }

            self._check_days();
            self._check_increments();
            self._input_format();
        });

        $(document).on('click touch','.air_duration_close',function() {
            self._hide();
        });

        $(document).on('click touch', function(e) {
            let container = $(self.container);
            let display_input = $(self.display_input);
            let target = e.target;

            if (target && container) {
                if (!container.is(target) && container.has(target).length === 0 &&
                    !display_input.is(target) && (document.getElementById(self.container_id) && !document.getElementById(self.container_id).contains(target))
                ) {
                    if (!$(target).hasClass('date_continue')) {
                        self._hide();
                    }
                }
            }
        });

        $(document).on('click touch', '.date_continue', function(e) {
            window.search_air._hideAirDatepicker();
            self._show();
        });
    },
    _hide() {
        // let self = this;
        // $(self.container).hide();
        // $('#collections_icons_index_listing').removeClass('extra_z_index2');
        // $('#collections_listing_search').removeClass('extra_z_index2');
        // $('#filter-btn-holder').removeClass('no_z_index3');
    },
    _show() {
        // let self = this;
        // $(self.container).show();
        //
        // $('#collections_icons_index_listing').addClass('extra_z_index2');
        // $('#collections_listing_search').addClass('extra_z_index2');
        // $('#filter-btn-holder').addClass('no_z_index3');
        // $(self.opt.base_div + " .datepicker_air").css("display"," block");

    },

    _click_increment(elem) {
        let self = this;
        let inc = $(elem).data('increment');

        let number_span = $(elem).parent().find('.day_count span');

        if ($(elem).data('type') === 1) {
            if (self.opt.days_from + +inc > 0 &&  self.opt.days_from + +inc <= self.opt.days_to) {
                self.opt.days_from += +inc;
                number_span.html(self.opt.days_from);
            }
        } else {
            if (self.opt.days_to + +inc < 22 &&  self.opt.days_to + +inc >= self.opt.days_from) {
                self.opt.days_to += +inc;
                number_span.html(self.opt.days_to);
            }
        }

        self._check_days();
        self._input_format();
    },
    _check_increments() {
        let self = this;
        $('.btn-increment[data-type="1"]').parent().find('.day_count span').html(self.opt.days_from);
        $('.btn-increment[data-type="2"]').parent().find('.day_count span').html(self.opt.days_to);
    },
    _check_days() {
        let self = this;
        $('.nights-btn').each(function() {
            if (
                parseInt($(this).data('from')) === self.opt.days_from &&
                ($(this).data('to') === self.opt.days_to || isNaN(self.opt.days_to))
            ) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        })
    },
    _click_range(elem) {
        let self = this;

        if (self.opt.days_from == $(elem).data('from') && self.opt.days_to == $(elem).data('to') && $(elem).hasClass('active')) {
            self.opt.days_from = undefined;
            self.opt.days_to = undefined;
        } else {
            self.opt.days_from = $(elem).data('from');
            self.opt.days_to = $(elem).data('to');
        }

        self._check_days();
        self._check_increments();

        self._input_format();
    },
    _input_format() {
        let self = this;

        let input = $(self.display_input);
        if (typeof(self.opt.days_from) != 'undefined') {
            //window
            let w_days_to_txt = (self.opt.days_to > 0) ? "-"+self.opt.days_to : '';
            input.html(self.opt.days_from + w_days_to_txt + ' n.');

            //mobile
            // $('.m_days_from').html(self.opt.days_from);
            // $('.m_days_to').html(self.opt.days_to);
            let days_to_txt = (self.opt.days_to > 0) ? "-"+self.opt.days_to : '';

            window.search_air.changeFilter('duration', self.opt.days_from + days_to_txt, self.opt.days_from + days_to_txt);
        } else {
            input.find('.desktop-txt').html(input.data('empty-txt'));
            input.find('.mobile-txt').html(input.data('empty-txt-mob'));
            window.search_air.changeFilter('duration', '', '');
        }
    },
}

window.mobileCheck = function() {
    if ($(window).width() < 992) {
        return true;
    }
    return false;
};
$(document).ready(function() {
    window.search_air.init();
    window.search_duration_air.init();
});

